

.create-album-modal {


    .inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        & > .input {
            margin-bottom: 10px;
        }

        .checkbox-content {
            display: flex;
            align-items: center;
            gap: 10px;
            .label {
                font-size: 16px;
                font-style: italic;
                cursor: default;
            }
            .input {

            }
        }

    }
}