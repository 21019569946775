
.image-view {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: transparent;
    backdrop-filter: blur(16px);

    .control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        .side {
            height: 100%;
            width: 75px;
            position: relative;
            z-index: 101;

            .arrow {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                transform: translate(0, -50%);
                cursor: pointer;
            }
            .close {
                position: absolute;
                top: 2%;
                left: 50%;
                transform: translate(-110%, 0);
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
            
            &.left {
                .arrow {
                    transform: translate(-75%, 0);
                }
            }
            &.right {
                .arrow {
                    transform: translate(-110%, 0);
                }
                
            }
        }
        
        .bottom {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 500px;
            background-color: #3f4144aa;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            z-index: 200;
            color: var(--white);

            @media (max-width: 700px) {
                padding: 15px 20px 40px;
            }
            .title {
                font-size: 18px;
                font-weight: 500;
            }
            .icons {
                display: flex;
                align-items: center;
                gap: 10px;
                
                .edit, .fullscreen {
                    cursor: pointer;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            @media (max-width: 510px) {
                width: calc(100vw - 40px);
            }
        }
        .close-mobile {
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 5px;
            background-color: #3f4144aa;
            z-index: 200;
            border-radius: 10px;
            width: 20px;
            height: 20px;
            img {
                width: 20px;
                height: 20px;
            }
            @media (max-width: 700px) {
                top: 40px;
            }
        }
        .count {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 5px 15px;
            background-color: #3f4144aa;
            z-index: 200;
            border-radius: var(--base-radius);
            color: var(--white);
            @media (max-width: 700px) {
                top: 40px;
            }
            span {
                display: block;
                // font-weight: 500;
            }
        }
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        z-index: 100;

        .photo {
            display: block;
            max-width: 80vw;
            max-height: 80vh;
            position: relative;
        }
    }


    &.fullscreen {
        background-color: #000000;

        .photo {
            max-inline-size: 100vw;
            max-block-size: 100vh;
        }

        .bottom {
            background-color: #000000df;
        }
        .count {
            background-color: #000000df;
        }
        .close-mobile {
            background-color: #000000df;
        }
    }
}