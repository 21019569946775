
.plans {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(4, 290px);
    grid-row-gap: 25px;
    grid-column-gap: 25px;
    justify-content: center;

    .plan {
        padding: 20px 0px;
        box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.33);
        border-radius: var(--base-radius);
        background-color: var(--white);
        // width: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        

        .title {
            text-align: center;
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .duration {
            text-align: center;
            font-size: 20px;
            margin-bottom: 5px;
        }
        .price {
            text-align: center;
            font-size: 15px;
            font-style: italic;
        }
        .benefits {
            padding: 0;
            margin-bottom: 70px;

            li {
                font-size: 18px;
                margin: 5px 0;
            }
        }
        .button {
            width: 100px;
        }
    }

    @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 400px);
    }
    @media (max-width: 900px) {
        grid-template-columns: repeat(1, 400px);
    }
    @media (max-width: 490px) {
        grid-template-columns: repeat(1, 290px);
    }
}