
.api {

    .container {
        padding-top: calc(105px + 50px);

        .content {
            margin-left: 320px;
            margin-bottom: 150px;

            .current-api {
                display: flex;
                align-items: center;
                gap: 35px;
                .api-label {
                    font-size: 20px;
                    font-style: italic;
                    @media (max-width: 640px) {
                        font-size: 14px;
                    }
                }
                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .button {
                        img {
                            width: 25px;
                            height: 25px;
                        }
                        
                    }
                }
            }
            .unauth-text {

            }
            .rapid-api-text {
                margin: 20px 0 40px;
            }
            .rapid-api-text > span {
                display: block;
                font-size: 20px;
                margin-bottom: 15px;
                a {
                    color: var(--base-color);
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
                @media (max-width: 640px) {
                    font-size: 18px;
                }
            }
        }

        @media (max-width: 900px) {
            .content {
                margin-left: 100px;
            }
        }
        @media (max-width: 640px) {
            .content {
                margin-left: 90px;
            }
        }
    }
}