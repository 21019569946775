

.main {
    

    .content {
        
        .whatis {
            margin: 40px 0;
            .title {
                font-size: 86px;
                font-weight: 500;
                text-align: center;
                line-height: 90%;
            }
            .text {
                display: block;
                font-size: 36px;
                text-align: center;
            }
        }

        .main-blocks {
            margin: 75px 0;
        }

        .premium-bar {
            box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.33);
            border-radius: var(--base-radius);
            padding: 20px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: 24px;
                font-weight: 500;
            }
            .more {
                width: max-content;
                font-size: 20px;
                color: var(--base-color);
                cursor: pointer;
                font-weight: 500;
            }
        }

        @media (max-width: 900px) {
            .whatis {
                .title {
                    font-size: 68px;
                }
                .text {
                    font-size: 24px;
                }
            }
            .premium-bar {
                flex-direction: column;
                .title {
                    margin-bottom: 20px;
                    text-align: center;
                }
            }
        }
        @media (max-width: 650px) {
            .whatis {
                .title {
                    font-size: 48px;
                }
                .text {
                    font-size: 18px;
                }
            }
        }
        @media (max-width: 480px) {
            .whatis {
                .title {
                    font-size: 36px;
                }
                .text {
                    font-size: 18px;
                }
            }
        }

    }
}