
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    z-index: 100;
    backdrop-filter: blur(8px);

    .content {
        position: absolute;
        width: 500px;
        padding: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--white);
        // border-radius: var(--base-radius);
        box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.33);

        @media (max-width: 640px) {
            width: 85vw;
        }

        .top {

            .title {
                font-size: 28px;
            }
            .types {
                display: flex;
                justify-content: center;
                .type {
                    cursor: pointer;
                    font-size: 17px;
                    padding: 0 20px 5px;
                    &.active {
                        font-weight: 500;
                        border-bottom: 2px solid var(--base-color);
                    }
                }
            }
        }
        .middle {
            margin: 20px 0;
        }
        .bottom {

            .buttons {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .mode {
                    &.auth {
                        margin-left: 10px;
                        color: var(--base-color);
                        font-size: 16px;
                        transition: all .3s ease 0s;
                        font-weight: 500;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    &.delete {
                        font-size: 14px;
                        font-weight: 600;
                        padding: 10px 20px;
                        background-color: var(--red);
                        border-radius: var(--base-radius);
                        color: var(--white);
                        cursor: pointer;
                        transition: all .3s ease 0s;
                        &:hover {
                            box-shadow: 0px 0px 13px -3px rgba(251, 53, 53, 1);
                        }
                    }
                    &.info {
                        font-size: 16px;
                        font-weight: 500;
                        font-style: italic;
                    }
                }
                .right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 25px;
                }
                
            }
        }
    }

    .error {
        position: absolute;
        width: 500px;
        padding: 10px 20px;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 100%);
        background-color: var(--red-pale);
        border-radius: var(--base-radius);
        color: var(--red);
        font-size: 16px;
        font-weight: 500;
        text-align: center;

        @media (max-width: 600px) {
            width: calc(100vw - 40px);
            border-radius: 0;
        }
    }

}