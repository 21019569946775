

.image-block {
    aspect-ratio: 1;
    border-radius: var(--base-radius);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .image-photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transform: scale(1);
        transition: all .3s ease 0s;
    }

    .image-gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, transparent 50%, transparent 50%);
        transition: all .3s ease 0s;
    }

    .bottom {
        margin: 0 25px 15px;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease 0s;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .title {
            font-size: 18px;
            max-width: 85%;
            overflow: hidden;
            color: var(--white);
        }
        .icon {

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    &:hover {
        .image-photo {
            transform: scale(1.1);
        }
        .image-gradient {
            background: linear-gradient(0deg, var(--font-color) 0%, transparent 50%);
        }
        .bottom {
            opacity: 1;
            visibility: visible;
        }
    }

}

