
.main-block {
    margin-top: 40px;
    height: 300px;
    display: flex;
    box-shadow: 0px 0px 18px -3px rgba(0,0,0,0.33);
    border-radius: var(--base-radius);
    overflow: hidden;

    &.r {
        flex-direction: row-reverse;
    }

    .image-content {
        min-width: 390px;
        height: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        transform: translateY(15%);
    }

    .text-content {
        // flex: 70%;
        margin: 20px 40px;
        height: 260px;
        overflow: hidden;
        position: relative;

        .title {
            font-size: 42px;
            margin-top: 10px;
        }
        .text {
            font-size: 24px;
            margin-top: 10px;
        }
        .more {
            position: absolute;
            width: 100%;
            bottom: 0;
            transform: translateY(20%);
            display: flex;
            justify-content: flex-start;
            padding: 15px 0;
            background: linear-gradient(0deg, var(--white) 75%, transparent 95%);
            border-radius: 0;
            
            div {
                font-size: 22px;
                color: var(--base-color);
                cursor: pointer;
                font-weight: 500;
            }
        }
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        padding-top: 15px;

        &.r {
            flex-direction: column;
        }
        .image-content {
            min-width: 0px;
            height: 50px;
            background-position: center;
            background-size: auto;
            transform: translateY(0);
        }
        .text-content {
            margin-top: 0;
            margin-bottom: 20px;
            .title {
                text-align: center;
                font-size: 42px;
                margin-top: 5px;
                line-height: 85%;
            }
            .text {
                font-size: 18px;
                margin-top: 15px;
            }
        }
        
    }
    @media (max-width: 480px) {
        .text-content > .title {
            font-size: 36px;
        }
    }
}