
.loader {

    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 200px;
        height: 200px;
        animation: spin 8s linear infinite;
    }
    &.small {
        position: static;
        width: 25px;
        height: 25px;
        img {
            width: 100%;
            height: 100%;
            animation: spin 8s linear infinite;
        }
    }
    @keyframes spin { 
        100% {
            transform:rotate(360deg); 
        } 
    }
}