
.edit-user-modal {


    .inputs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .input:first-child {
            // margin-bottom: 20px;
        }

        .forget {
            font-size: 14px;
            margin-left: 10px;
        }
    }
}