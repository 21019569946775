

.about-block {

    &:not(:first-child) {
        margin-top: 100px;
    }

    .title {
        font-size: 76px;
        font-weight: 500;
    }
    .text {
        font-size: 24px;
        line-height: 1.5;
        overflow-wrap: break-word;
    }

    @media (max-width: 640px) {
        margin-bottom: 70px;
        .title {
            font-size: 48px;
        }
        .text {
            font-size: 18px;
        }
    }
    @media (max-width: 420px) {
        .title {
            font-size: 36px;
        }
        .text {
            font-size: 16px;
        }
    }
}